import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { addPaymentMethod, changeSelectedPaymentMethod, setIsComingFromCheckout } from '../../../reducers/StoreSlice';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { PaymentService } from '../../../services/drsbee/data/PaymentService';
import Swal from 'sweetalert2';
import { Utilities } from '../../../utility/Utilities';
const paymentService = new PaymentService();
const util = new Utilities();
const AddPaymentMethodForm = ( {isGuest} ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state) => state.store.paymentMethods);
  const environment = useSelector((state) => state.store.environment);
  const [cardAlias, setCardAlias] = useState(isGuest ? 'Guest Payment Method' : '');
  const [cardNumber, setCardNumber] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState(''); 
  const [cvv, setCVV] = useState('');
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [cipher, setCipher] = useState(null);
  const [loading, setLoading] = useState(false);
  const isComingFromCheckout = useSelector((state) => state.store.isComingFromCheckout);

  const handleCardNumberChange = (e) => setCardNumber(e.target.value);
  const handleCardholderNameChange = (e) => setCardholderName(e.target.value);
  const handleCardAliasChange = (e) => setCardAlias(e.target.value);
  const handleMonthChange = (e) => setExpirationMonth(e.target.value);
  const handleYearChange = (e) => setExpirationYear(e.target.value);
  const handleCVVChange = (e) => setCVV(e.target.value);

  const handleGoBack = () => navigate(-1);

  const handleConfirm = () => {
    if (
      (!isGuest && cardAlias.length < 6) ||
      cardNumber.length < 16 ||
      cardholderName.length < 8 ||
      expirationMonth === '' ||
      expirationYear === '' ||
      cvv.length < 3
    ) {
      return;
    }

    setLoading(true);

    const creditCard = {
      cardAlias,
      cardNumber,
      cardholderName,
      expirationMonth,
      expirationYear,
      cvv,
    };

    if (cipher !== null) {
      let cipheredCard = cipher.cipherCreditCard(cardNumber, expirationMonth, expirationYear, cvv, cardholderName, cardAlias);
      let gpPayload = { "ccData": cipheredCard.cipheredData, "keyData": cipheredCard.keyData };
      if(!isGuest){
        paymentService.addPaymentMethod(gpPayload).then(response => {
          setLoading(false);
          if (response.successful && response.value !== null) {
            dispatch(addPaymentMethod(response.value));
            dispatch(changeSelectedPaymentMethod(response.value));
            window.localStorage.setItem('selectedPaymentMethod', JSON.stringify(response.value));
            if(isComingFromCheckout){
              dispatch(setIsComingFromCheckout(false));
              navigate('/store/cart/checkout');
            }else{
              navigate('/store/cart');
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error al agregar método de pago',
              text: 'Ocurrió un error al agregar el método de pago. Por favor intente de nuevo.',
              confirmButtonText: 'OK'
            });
          }
        });
      }else{
        let paymentMethod = {"ccData": cipheredCard.cipheredData, "keyData": cipheredCard.keyData, "maskedNumber": util.maskCreditCardNumber(cardNumber), "cardHolderName": cardholderName};  
        dispatch(addPaymentMethod(paymentMethod));
        dispatch(changeSelectedPaymentMethod(paymentMethod));
        window.localStorage.setItem('selectedPaymentMethod', JSON.stringify(paymentMethod));
        if(isComingFromCheckout){
          dispatch(setIsComingFromCheckout(false));
          navigate('/store/cart/checkout');
        }else{
          navigate(-1);
        }
      }
    }
  };

  const isFormValid = (cardAlias.length >= 6 || isGuest) && cardNumber.length === 16 && cardholderName.length >= 6 && expirationMonth !== '' && expirationYear !== '' && cvv.length >= 3;

  useEffect(() => {
    const loadScripts = async () => {
      const scriptPromises = environment.creditCardEncryptionJSLibs.map((scriptUrl) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = scriptUrl;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });
      });

      try {
        await Promise.all(scriptPromises);
        setIsScriptLoaded(true);
      } catch (error) {
        console.error('Failed to load credit card encryption scripts:', error);
      }
    };

    loadScripts();
  }, [environment]);

  useEffect(() => {
    if (isScriptLoaded) {
      const GreenPayCipher = window.GreenPayCipher;      
      if (GreenPayCipher) {
        setCipher(new GreenPayCipher(environment.creditCardEncryptionPublicKey));
      } else {
        console.error('GreenPayCipher is not available. Make sure the script has been loaded correctly.');
      }
    }
  }, [isScriptLoaded, environment]);

  const generateMonthOptions = () => {
    return Array.from({ length: 12 }, (_, i) => {
      const month = (i + 1).toString().padStart(2, '0');
      return <option key={month} value={month}>{month}</option>;
    });
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 10 }, (_, i) => {
      const year = (currentYear + i).toString().slice(2);
      return <option key={year} value={year}>{year}</option>;
    });
  };

  return (
    <>
      {isScriptLoaded ? (
        <Card>
          <Card.Body>
            <Form>
              {!isGuest ?
              (
                <Form.Group controlId="cardAlias">
                  <Form.Label>Alias de la tarjeta</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Alias de la tarjeta (Crédito / Débito / Etc)"
                    value={cardAlias}
                    onChange={handleCardAliasChange}
                  />
                  {cardAlias.length >= 1 && cardAlias.length < 6 && (
                    <Form.Text className="text-danger">El alias debe tener al menos 6 carácteres.</Form.Text>
                  )}
                </Form.Group>
              ) : null
              }
              <Form.Group controlId="cardNumber">
                <Form.Label>Número de tarjeta</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Digite el número de tarjeta"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                />
                {cardNumber >= 1 && cardNumber.length < 16 && (
                  <Form.Text className="text-danger">El número de tarjeta debe tener 16 dígitos.</Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="cardholderName">
                <Form.Label>Nombre del tarjetahabiente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite el nombre en la tarjeta"
                  value={cardholderName}
                  onChange={handleCardholderNameChange}
                />
                {cardholderName.length >= 1 && cardholderName.length < 6 && (
                  <Form.Text className="text-danger">El nombre en la tarjeta es requerido. Digite el nombre tal cual como se muestra en la tarjeta</Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="expiration">
                <Form.Label>Fecha de expiración</Form.Label>
                <div className="d-flex">
                  <Form.Control as="select" value={expirationMonth} onChange={handleMonthChange}>
                    <option value="">Mes</option>
                    {generateMonthOptions()}
                  </Form.Control>
                  <Form.Control as="select" value={expirationYear} onChange={handleYearChange}>
                    <option value="">Año</option>
                    {generateYearOptions()}
                  </Form.Control>
                </div>
              </Form.Group>

              <Form.Group controlId="cvv">
                <Form.Label>CVV</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Digite el código al reverso de la tarjeta"
                  value={cvv}
                  onChange={handleCVVChange}
                />
                {cvv.length >= 1 && cvv.length < 3 && (
                  <Form.Text className="text-danger">El CVV es requerido.</Form.Text>
                )}
              </Form.Group>
            </Form>
            {loading ? 
              <div className="d-flex flex-row justify-content-center m-5">
                <LoadingSpinner /><h4>Registrando método de pago</h4>
              </div>
              : null}
          </Card.Body>
          <Card.Footer className="d-flex flex-row justify-content-between">
            <Button variant="secondary" onClick={handleGoBack}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleConfirm} disabled={!isFormValid}>
              Agregar
            </Button>
          </Card.Footer>
        </Card>
      ) : (
        <div>Loading scripts...</div>
      )}
    </>
  );
};

export default AddPaymentMethodForm;
